/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useMemo, useRef, useState } from "react"
import { Box, Button, IconButton, Tooltip } from "@mui/material"
import { Refresh, Visibility } from "@mui/icons-material"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { useQuery } from "react-query"
import { DateTime } from "luxon"
import { useSnackbar } from "notistack"

import useAxiosPrivate from "../../../Hooks/useAxiosPrivate.js"
import customFilterPanel from "../../../Component/customFilter.jsx"
import filterModel from "../../../Utils/filterModel.js"
import CustomExportOptions from "../../../Component/customExport.jsx"
import CustomeDateFilter from "../../../Component/customDateFilterPanel.jsx"
import HasPermission from "../../../Utils/access.js"
import Unauthorized from "../../../Component/unauthorized.jsx"
import handleDateExport from "../../../Utils/File Export Formatter/dateFilterFormat.js"
import { roundToTwoDecimalPlaces } from "../../../Utils/dataFormat.js"
import ViewPackageTripDetails from "./packageTripDetails.jsx"

// Packages List Page Component
function Packages() {
    // USESTATE HOOK
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        first_name: "contains",
        middle_name: "contains",
        last_name: "contains",
        email: "contains",
        user_phone: "contains",
        trip_type: "is",
        user_type: "is",
        amount: "=",
        created_at: "is",
        updated_at: "is",
        status: "is",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([
        {
            field: "created_at",
            sort: "desc",
        },
    ])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })
    const [open, setOpen] = useState(false)

    const tripDetail = useRef({ id: null, type: null })

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id || item?.field,
                sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()
    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isFetching, data, refetch } = useQuery(
        [
            "packages",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
            columnFilters,
        ],
        () =>
            axiosPrivate.get(`/system/efloat/package`, {
                params: {
                    page: `${pagination.pageIndex}`,
                    per_page: `${pagination.pageSize}`,
                    filter: JSON.stringify(
                        filterModel(filterFns, columnFilters) ?? undefined
                    ),
                    sort: JSON.stringify(sort ?? []),
                    search: globalFilter ?? undefined,
                    link_operator: "and",
                },
            }),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled: HasPermission("get package transactions"),
        }
    )

    // HELPERS and EVENT HANDLERS
    const getStatus = useCallback(
        ({ row }) => (
            <Box>
                {(row?.original?.status !== "" ||
                    row?.original?.status !== undefined) && (
                    <Button
                        key={row?.original?.id}
                        disableElevation
                        variant="contained"
                        sx={{
                            borderRadius: "10px",
                            fontWeight: "bold",
                            backgroundColor:
                                row?.original?.status === "SUCCEEDED"
                                    ? "#E5FFEA"
                                    : "#ffd6d6",
                            color:
                                row?.original?.status === "SUCCEEDED"
                                    ? "#16C138"
                                    : "red",
                            "&: hover": {
                                backgroundColor:
                                    row?.original?.status === "SUCCEEDED"
                                        ? "#E5FFEA"
                                        : "#ffd6d6",
                                color:
                                    row?.original?.status === "SUCCEEDED"
                                        ? "#16C138"
                                        : "red",
                            },
                            width: 160,
                            py: 1,
                        }}
                    >
                        {row?.original?.status}
                    </Button>
                )}
            </Box>
        ),
        []
    )
    const action = useCallback(
        ({ row }) => (
            <Box sx={{ flex: 1 }}>
                <IconButton
                    sx={{
                        color: "primary.main",
                    }}
                    onClick={() => {
                        tripDetail.current = {
                            id: row?.original?.trip_id,
                            type: row?.original?.trip_type,
                        }
                        setOpen(true)
                    }}
                >
                    <Tooltip title="Trip Details">
                        <Visibility />
                    </Tooltip>
                </IconButton>
            </Box>
        ),
        []
    )
    const datePicker = (props) => <CustomeDateFilter {...props} />

    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                size: 220,
                accessorKey: "created_at",
                header: "Registered Time",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                size: 200,
                accessorKey: "amount",
                header: "Amount (ETB)",
                filterVariant: "number",
                Cell: ({ cell }) =>
                    cell?.getValue() > 0
                        ? `${roundToTwoDecimalPlaces(cell?.getValue())}`
                        : `${cell?.getValue()}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "first_name",
                header: "First Name",
                Cell: ({ row }) => row?.original?.user?.first_name,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 200,
                accessorKey: "middle_name",
                header: "Middle Name",
                Cell: ({ row }) => row?.original?.user?.middle_name,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "last_name",
                header: "Last Name",
                Cell: ({ row }) => row?.original?.user?.last_name,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 180,
                accessorKey: "user_phone",
                header: "Phone",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "email",
                header: "Last Name",
                Cell: ({ row }) => row?.original?.user?.email,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "user_type",
                header: "User Type",
                filterVariant: "select",
                filterSelectOptions: ["USER", "DRIVER"],
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 230,
                accessorKey: "trip_type",
                header: "Trip Type",
                filterVariant: "select",
                filterSelectOptions: ["Ride Trip", "Ride Plus Trip"],
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "status",
                header: "Status",
                filterVariant: "select",
                filterSelectOptions: ["FAILED", "SUCCEEDED"],
                Cell: getStatus,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "updated_at",
                header: "Last Updated",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                accessorKey: "action",
                header: "Action",
                flex: 1,
                enableSorting: false,
                enableColumnFilter: false,
                Cell: action,
            },
        ],
        [action, getStatus]
    )
    // RENDER
    if (!HasPermission("get package transaction")) {
        return <Unauthorized />
    }
    return (
        <Box>
            {open && (
                <ViewPackageTripDetails
                    tripData={tripDetail.current}
                    open={open}
                    handleClose={() => setOpen(false)}
                />
            )}
            <Box sx={{ height: "100%" }}>
                <MaterialReactTable
                    columns={columns}
                    data={data?.data?.data ?? []}
                    initialState={{
                        columnPinning: {
                            left: ["mrt-row-select"],
                            right: ["action"],
                        },
                    }}
                    enableColumnResizing
                    enableColumnFilterModes
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    manualFiltering
                    manualPagination
                    manualSorting
                    filterFns={{
                        after: (row, filterValue) =>
                            row.customField === filterValue,
                    }}
                    muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                        helperText: `Filter Mode: ${filterFns[column?.id]}`,
                    })}
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setFilterFns}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <Tooltip arrow title="Refresh Data">
                                <IconButton onClick={() => refetch()}>
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <CustomExportOptions
                                table={table}
                                model={filterModel(filterFns, columnFilters)}
                                page="/system/efloat/package"
                                formatter={handleDateExport}
                            />
                            <MRT_ToggleFiltersButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableBodyCellProps={({ table, column }) => {
                        const columnName = column.id
                        const pinnedState = table.getState().columnPinning

                        const isLeftPinned = pinnedState?.left?.some(
                            (el) => el === columnName
                        )
                        const isRightPinned = pinnedState?.right?.some(
                            (el) => el === columnName
                        )
                        if (isLeftPinned || isRightPinned)
                            return {
                                sx: {
                                    "&.MuiTableCell-root": {
                                        boxShadow: isRightPinned
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : "7px 0px 10px -1.7px lightgray",
                                    },
                                },
                            }
                        return null
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    state={{
                        columnFilters,
                        filterFns,
                        globalFilter,
                        isFetching,
                        pagination,
                        showSkeletons: isFetching,
                        sorting,
                    }}
                />
            </Box>
        </Box>
    )
}

export default Packages
