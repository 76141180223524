/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
    Avatar,
    Box,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from "@mui/material"
import { Edit, MoreVert, Refresh } from "@mui/icons-material"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { useQuery } from "react-query"
import { DateTime } from "luxon"
import { useSnackbar } from "notistack"
import { useNavigate } from "react-router-dom"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import EditCorporate from "./editCompany.jsx"
import StatusButton from "../../Component/statusButton.jsx"
import AddCorporates from "./createCompanies.jsx"
import customFilterPanel from "../../Component/customFilter.jsx"
import filterModel from "../../Utils/filterModel.js"
import CustomExportOptions from "../../Component/customExport.jsx"
import HasPermission from "../../Utils/access.js"
import Unauthorized from "../../Component/unauthorized.jsx"
import { RP_ASSETS_URL, BASE_URL, VERSION } from "../../Utils/config.js"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import TopUp from "./Billing/companyTopUp.jsx"
import handleDateExport from "../../Utils/File Export Formatter/dateFilterFormat.js"
import { roundToTwoDecimalPlaces } from "../../Utils/dataFormat.js"
import ChangeStatusButton from "../../Component/changeStatus.jsx"
import useUpdateEntity from "../../Hooks/useUpdateEntity.js"

// List Companies Component
function Companies() {
    const navigate = useNavigate()

    // USESTATE HOOK
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        name: "contains",
        phone: "contains",
        email: "contains",
        created_at: "is",
        updated_at: "is",
        account_type: "is",
        allow_voucher: "is",
        allow_discount: "is",
        status: "is",
        balance: "=",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([
        {
            field: "created_at",
            sort: "desc",
        },
    ])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })
    const [open, setOpen] = useState(false)
    const [update, setUpdate] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [corporate, setCorporate] = useState()
    const [openTopUp, setOpenTopUp] = useState(false)

    const companyData = useRef()

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id || item?.field,
                sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // QUERY & MUTATION
    const { isFetching, data, refetch } = useQuery(
        [
            "companies",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
            columnFilters,
        ],
        () =>
            axiosPrivate.get(`/system/companies`, {
                params: {
                    page: `${pagination.pageIndex}`,
                    per_page: `${pagination.pageSize}`,
                    filter: JSON.stringify(
                        filterModel(filterFns, columnFilters) ?? undefined
                    ),
                    sort: JSON.stringify(sort ?? []),
                    search: globalFilter ?? undefined,
                    link_operator: "and",
                },
            }),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
        }
    )
    const {
        mutate,
        data: updateStatusResponse,
        isLoading: updateStatusIsLoading,
    } = useUpdateEntity(`system/companies`, "Succesfully changed status.")
    const {
        mutate: updateVoucher,
        data: updateVoucherResponse,
        isLoading: updateVoucherIsLoading,
    } = useUpdateEntity(
        `/system/companies`,
        "Succesfully updated voucher status."
    )
    const {
        mutate: updateDiscount,
        data: updateDiscountResponse,
        isLoading: updateDiscountIsLoading,
    } = useUpdateEntity(
        `/system/companies`,
        "Succesfully updated discount status."
    )

    // HELPERS and EVENT HANDLERS
    const handleSwitch = useCallback(
        (company) => {
            mutate({
                id: company.id,
                status: company.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
            })
        },
        [mutate]
    )
    const handleVoucherStatus = useCallback(
        (company) => {
            updateVoucher({
                id: company.id,
                allow_voucher: !company.allow_voucher,
            })
        },
        [updateVoucher]
    )
    const handleDiscountStatus = useCallback(
        (company) => {
            updateDiscount({
                id: company.id,
                allow_discount: !company.allow_discount,
            })
        },
        [updateDiscount]
    )
    const handleEdit = (company) => {
        companyData.current = company
        setUpdate(true)
        return companyData.current
    }
    const handleEditModalClose = () => {
        setUpdate(false)
    }
    const handleModalClose = () => {
        setOpen(false)
    }
    const getAvatar = useCallback(
        ({ cell }) => (
            <Avatar
                variant={
                    cell?.row?.original?.logo === undefined
                        ? "rounded"
                        : "square"
                }
                sx={{
                    width: 60,
                    height: 50,
                }}
                src={`${BASE_URL}/${VERSION}/${RP_ASSETS_URL}/company_logo/${cell?.row?.original?.logo}`}
            />
        ),
        []
    )
    const getStatus = useCallback(
        ({ cell }) => (
            <StatusButton
                loading={updateStatusIsLoading}
                status={cell.getValue()}
                unauthorized={!HasPermission("update company information")}
                onChange={() => handleSwitch(cell?.row?.original)}
            />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [updateStatusIsLoading]
    )
    const voucherStatus = useCallback(
        ({ cell }) => (
            <ChangeStatusButton
                loading={updateVoucherIsLoading}
                status={cell.getValue()}
                unauthorized={!HasPermission("update company information")}
                onChange={() => handleVoucherStatus(cell?.row?.original)}
            />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [updateVoucherIsLoading]
    )
    const discountStatus = useCallback(
        ({ cell }) => (
            <ChangeStatusButton
                loading={updateDiscountIsLoading}
                status={cell.getValue()}
                unauthorized={!HasPermission("update company information")}
                onChange={() => handleDiscountStatus(cell?.row?.original)}
            />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [updateVoucherIsLoading]
    )
    const actions = useCallback(
        ({ row }) => (
            <Box sx={{ flex: 1 }}>
                <Button
                    disabled={!HasPermission("update company information")}
                    sx={{ minWidth: 24, p: 0, color: "primary.main" }}
                    onClick={() => handleEdit(row?.original)}
                >
                    <Edit />
                </Button>
                <IconButton
                    sx={{
                        color: "primary.main",
                    }}
                    onClick={(e) => {
                        setAnchorEl(e.currentTarget)
                        setCorporate(row?.original)
                    }}
                >
                    <MoreVert />
                </IconButton>
                <Menu
                    key={row?.original.id}
                    id={row?.original.id}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem
                        disabled={!HasPermission("list company system users")}
                        onClick={() => {
                            navigate(
                                `${encodeURIComponent(corporate?.name)}/users`,
                                {
                                    state: { corporateId: corporate?.id },
                                }
                            )
                            setAnchorEl(null)
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#707070",
                            }}
                        >
                            System Users
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        disabled={
                            !(
                                HasPermission("list company users") ||
                                HasPermission(
                                    "list corporate program invited users"
                                )
                            )
                        }
                        onClick={() => {
                            navigate(
                                `${encodeURIComponent(
                                    corporate?.name
                                )}/peoples`,
                                {
                                    state: { corporateId: corporate?.id },
                                }
                            )
                            setAnchorEl(null)
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#707070",
                            }}
                        >
                            Peoples
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        disabled={!HasPermission("list all transactions")}
                        onClick={() => {
                            navigate(
                                `${encodeURIComponent(
                                    corporate?.name
                                )}/transactions`,
                                {
                                    state: { corporateId: corporate?.id },
                                }
                            )
                            setAnchorEl(null)
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#707070",
                            }}
                        >
                            Transactions
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        disabled={!HasPermission("list corporate programs")}
                        onClick={() => {
                            navigate(
                                `${encodeURIComponent(
                                    corporate?.name
                                )}/programs`,
                                {
                                    state: { corporateId: corporate?.id },
                                }
                            )
                            setAnchorEl(null)
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#707070",
                            }}
                        >
                            Programs
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        disabled={
                            !(
                                HasPermission("list company invoices") ||
                                HasPermission("get pre paid top ups")
                            )
                        }
                        onClick={() => {
                            if (corporate?.account_type === "POSTPAID") {
                                navigate(
                                    `${encodeURIComponent(
                                        corporate?.name
                                    )}/invoices`,
                                    {
                                        state: {
                                            companyData: corporate,
                                            corporateId: corporate?.id,
                                        },
                                    }
                                )
                            }
                            if (corporate?.account_type === "PREPAID") {
                                navigate(
                                    `${encodeURIComponent(
                                        corporate?.name
                                    )}/receipts`,
                                    {
                                        state: {
                                            companyData: corporate,
                                            corporateId: corporate?.id,
                                        },
                                    }
                                )
                            }
                            setAnchorEl(null)
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#707070",
                            }}
                        >
                            Billing
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        key={corporate?.id}
                        onClick={() => {
                            navigate(`${corporate?.id}/details`, {
                                state: {
                                    companyData: corporate,
                                    id: corporate?.id,
                                },
                            })
                            setAnchorEl(null)
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#707070",
                            }}
                        >
                            Details
                        </Typography>
                    </MenuItem>
                </Menu>
            </Box>
        ),
        [anchorEl, corporate, navigate]
    )
    const datePicker = (props) => <CustomeDateFilter {...props} />

    useEffect(() => {
        if (
            updateStatusResponse ||
            updateVoucherResponse ||
            updateDiscountResponse
        ) {
            refetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateStatusResponse, updateVoucherResponse, updateDiscountResponse])

    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                size: 150,
                accessorKey: "logo",
                header: "Logo",
                enableSorting: false,
                flex: 1,
                enableColumnFilter: false,
                align: "center",
                Cell: getAvatar,
            },
            {
                accessorKey: "name",
                header: "Company",
                filterVariant: "string",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 180,
                accessorKey: "phone",
                header: "Phone",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 200,
                accessorKey: "email",
                header: "Email",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "created_at",
                header: "Created At",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                size: 220,
                accessorKey: "updated_at",
                header: "Updated At",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                size: 220,
                accessorKey: "account_type",
                header: "Type",
                filterVariant: "select",
                filterSelectOptions: ["PREPAID", "POSTPAID"],
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "balance",
                header: "Balance (ETB)",
                filterVariant: "number",
                Cell: ({ cell }) =>
                    roundToTwoDecimalPlaces(Number(cell?.getValue())),
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "allow_voucher",
                header: "Voucher Status",
                filterVariant: "boolean",
                enableSorting: false,
                enableColumnFilter: false,
                Cell: voucherStatus,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "allow_discount",
                header: "Discount Status",
                filterVariant: "boolean",
                enableSorting: false,
                enableColumnFilter: false,
                Cell: discountStatus,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "status",
                header: "Status",
                filterVariant: "select",
                filterSelectOptions: ["ACTIVE", "INACTIVE"],
                Cell: getStatus,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "action",
                header: "Action",
                enableSorting: false,
                flex: 1,
                enableColumnFilter: false,
                Cell: actions,
            },
        ],
        [actions, discountStatus, getAvatar, getStatus, voucherStatus]
    )
    // RENDER
    if (!HasPermission("list companies")) return <Unauthorized />

    return (
        <Box sx={{ width: "100%" }}>
            <Box>
                {open && (
                    <AddCorporates
                        handleModalClose={handleModalClose}
                        refetch={refetch}
                        open={open}
                    />
                )}
                {update && (
                    <EditCorporate
                        handleEditModalClose={handleEditModalClose}
                        refetch={refetch}
                        update={update}
                        company={companyData.current}
                    />
                )}
                {openTopUp && (
                    <TopUp
                        accountType={corporate?.account_type}
                        companyId={corporate?.id}
                        open={openTopUp}
                        setOpen={setOpenTopUp}
                        refetch={refetch}
                    />
                )}
                <Box sx={{ flex: 1, height: "100%" }}>
                    <MaterialReactTable
                        columns={columns}
                        data={data?.data?.data ?? []}
                        initialState={{
                            columnPinning: {
                                left: ["mrt-row-select", "logo"],
                                right: ["action"],
                            },
                        }}
                        enableColumnResizing
                        enableColumnFilterModes
                        enableStickyHeader
                        enableColumnOrdering
                        enableRowSelection
                        enablePinning
                        manualFiltering
                        manualPagination
                        manualSorting
                        filterFns={{
                            after: (row, filterValue) =>
                                row.customField === filterValue,
                        }}
                        muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                            helperText: `Filter Mode: ${filterFns[column?.id]}`,
                        })}
                        onColumnFiltersChange={setColumnFilters}
                        onColumnFilterFnsChange={setFilterFns}
                        onGlobalFilterChange={setGlobalFilter}
                        onPaginationChange={setPagination}
                        onSortingChange={setSorting}
                        renderTopToolbarCustomActions={() => (
                            <Box
                                sx={{
                                    display: "flex",
                                }}
                            >
                                {HasPermission("create company") && (
                                    <Button
                                        size="small"
                                        sx={{
                                            bgcolor: "primary.main",
                                            color: "white",
                                            px: 2,
                                            mx: 1,
                                            boxShadow: 2,
                                            " :hover": {
                                                bgcolor: "primary.main",
                                                color: "white",
                                            },
                                        }}
                                        onClick={() => setOpen(true)}
                                    >
                                        Add Corporate
                                    </Button>
                                )}
                            </Box>
                        )}
                        renderToolbarInternalActions={({ table }) => (
                            <>
                                <Tooltip arrow title="Refresh Data">
                                    <IconButton onClick={() => refetch()}>
                                        <Refresh />
                                    </IconButton>
                                </Tooltip>
                                <MRT_ToggleGlobalFilterButton table={table} />
                                <CustomExportOptions
                                    table={table}
                                    model={filterModel(
                                        filterFns,
                                        columnFilters
                                    )}
                                    page="/system/companies"
                                    formatter={handleDateExport}
                                />
                                <MRT_ToggleFiltersButton table={table} />
                                <MRT_ShowHideColumnsButton table={table} />
                                <MRT_ToggleDensePaddingButton table={table} />
                                <MRT_FullScreenToggleButton table={table} />
                            </>
                        )}
                        muiTableBodyCellProps={({ table, column }) => {
                            const columnName = column.id
                            const pinnedState = table.getState().columnPinning

                            const isLeftPinned = pinnedState?.left?.some(
                                (el) => el === columnName
                            )
                            const isRightPinned = pinnedState?.right?.some(
                                (el) => el === columnName
                            )
                            if (isLeftPinned || isRightPinned)
                                return {
                                    sx: {
                                        "&.MuiTableCell-root": {
                                            boxShadow: isRightPinned
                                                ? "-7px 0px 10px -1.7px lightgray"
                                                : "7px 0px 10px -1.7px lightgray",
                                        },
                                    },
                                }
                            return null
                        }}
                        muiTableContainerProps={{
                            sx: { maxHeight: `calc(100vh - 225px)` },
                        }}
                        muiTableHeadCellProps={{
                            sx: {
                                "& .Mui-TableHeadCell-Content": {
                                    justifyContent: "space-between",
                                },
                            },
                        }}
                        rowCount={data?.data?.meta_data?.total ?? 0}
                        state={{
                            columnFilters,
                            filterFns,
                            globalFilter,
                            isFetching,
                            pagination,
                            showSkeletons: isFetching,
                            sorting,
                        }}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default Companies
