import React, { useEffect, useRef, useState } from "react"
import {
    Button,
    CircularProgress,
    Typography,
    Box,
    TextField,
    Stepper,
    StepContent,
    StepLabel,
    Step,
    DialogTitle,
    IconButton,
    Divider,
    Dialog,
    DialogContent,
    DialogActions,
    Avatar,
    Tooltip,
    Grid,
    Alert,
} from "@mui/material"
import { useQuery } from "react-query"
import { Close, Download, RemoveRedEye, Search } from "@mui/icons-material"

import FilterReceiptsByTime from "./filterReceiptByTime.jsx"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import handleExportRows from "../../Utils/handleExport.js"
import inverseLogo from "../../Assets/inverse_logo.svg"
import ridePlusLogo from "../../Assets/ride_logo.png"
import handleReceiptExport from "../../Utils/File Export Formatter/receiptExportFormat.js"

// Search Receipts by TIN Component
function SearchReceiptsByTIN({
    open,
    setOpen,
    refetch,
    filteredData,
    setFilteredData,
}) {
    const [activeStep, setActiveStep] = useState(0)
    const [pageStatus, setPageStatus] = useState("SEARCHING")
    const [searchText, setSearchText] = useState()
    const id = useRef({})

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY
    const {
        isLoading: searchLoading,
        data: searchData,
        error: searchError,
        refetch: searchRefetch,
    } = useQuery(
        ["find_receipt", searchText],
        () =>
            axiosPrivate.get(`/system/receipts`, {
                params: {
                    per_page: -1,
                    filter: JSON.stringify([
                        {
                            column_field: "tin_number",
                            operator_value: "contains",
                            value: searchText,
                        },
                    ]),
                },
            }),
        {
            onSuccess: (responseData) =>
                responseData?.data?.data && setActiveStep(1),
            refetchOnWindowFocus: false,
            retry: false,
            enabled: false,
        }
    )
    // EVENT HANDLERS & HELPERS
    const handleChange = (event) => {
        if (event.target.value?.length === 10) {
            setSearchText(event.target.value)
            setTimeout(() => searchRefetch(), 100)
        } else if (event.target.value?.length < 10) {
            setSearchText(event.target.value)
            setPageStatus("INPUT_LENGTH_DECREASED")
        }
        return event.target.value
    }

    // USEFFECTS
    useEffect(() => {
        if (searchError || searchData?.data?.data === null) {
            setPageStatus("NOT_FOUND")
        }
    }, [searchError, searchData])
    useEffect(() => {
        if (searchData) {
            if (searchData?.data?.data) {
                setPageStatus("RECEIPT_FOUND")
                id.current = searchData?.data?.data?.id
            }
        }
    }, [searchData])

    // HELPERS and EVENT HANDLERS
    const handleNext = () => {
        setActiveStep((prev) => prev + 1)
    }

    const handleBack = () =>
        activeStep === 0 ? setOpen(false) : setActiveStep((prev) => prev - 1)
    const formattedData =
        filteredData?.data && handleReceiptExport(filteredData?.data)

    // STEPPER CONTENT
    const steps = [
        {
            label: "Start with TIN",
            content: searchLoading ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        m: 2,
                    }}
                >
                    <CircularProgress size={40} sx={{ color: "black" }} />
                </Box>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        flex: 1,
                        m: 2,
                    }}
                >
                    <TextField
                        autoFocus
                        size="small"
                        label="TIN"
                        type="number"
                        variant="outlined"
                        value={searchText}
                        placeholder="0011223344"
                        disabled={searchLoading}
                        error={pageStatus === "NOT_FOUND"}
                        onChange={(event) => handleChange(event)}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <IconButton title="Search" aria-label="Search">
                                    <Search color="primary" />
                                </IconButton>
                            ),
                        }}
                    />
                    {pageStatus === "NOT_FOUND" && (
                        <Box
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                                bgcolor: "#f6f7f7",
                                boxShadow: 3,
                                mt: 3,
                            }}
                        >
                            {" "}
                            <Alert severity="error" sx={{ p: 2 }}>
                                Receipt with the provided TIN is not found on
                                the system!
                            </Alert>
                        </Box>
                    )}
                </Box>
            ),
        },
        {
            label: "Monthly | Yearly",
            content: (
                <Box
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "#f6f7f7",
                        boxShadow: 3,
                        my: 3,
                        p: 2,
                    }}
                >
                    <FilterReceiptsByTime
                        tinValue={searchText}
                        setFilteredData={setFilteredData}
                    />
                    {filteredData?.data === null && (
                        <Box
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                                bgcolor: "#f6f7f7",
                                boxShadow: 3,
                                mt: 3,
                            }}
                        >
                            {" "}
                            <Alert severity="error" sx={{ p: 2 }}>
                                Receipt with the provided Date is not found on
                                the system!
                            </Alert>
                        </Box>
                    )}
                </Box>
            ),
        },
        {
            label: "View Details | Download",
            content: (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <IconButton
                        sx={{
                            color: "primary.main",
                            ":hover": {
                                bgcolor: "inherit",
                                transform: "scale(1.1)",
                            },
                            gap: "0.5em",
                            fontSize: 16,
                        }}
                        onClick={() => {
                            setOpen(false)
                            refetch()
                        }}
                    >
                        View Details <RemoveRedEye />
                    </IconButton>
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        color="black"
                    />
                    <IconButton
                        sx={{
                            color: "primary.main",
                            ":hover": {
                                bgcolor: "inherit",
                                transform: "scale(1.1)",
                            },
                            gap: "0.5em",
                            fontSize: 16,
                        }}
                        onClick={() => handleExportRows(formattedData)}
                    >
                        Dowload
                        <Download />
                    </IconButton>
                </Box>
            ),
        },
    ]

    // RENDER
    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            scroll="paper"
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{
                sx: {
                    minWidth: "md" && "70ch",
                },
            }}
        >
            <DialogTitle
                sx={{
                    backgroundColor: "#FAFAFA",
                    py: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Grid container>
                    <Grid xs={11} lg={11} sm={11} xl={11} md={11}>
                        <Typography
                            flex={5}
                            variant="h5"
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                            Search Receipt By TIN
                        </Typography>
                    </Grid>
                    <Grid xs={1} lg={1} sm={1} xl={1} md={1}>
                        <Tooltip title="Close Modal" arrow enterDelay={50}>
                            <IconButton
                                flex={0.5}
                                onClick={() => {
                                    setOpen(false)
                                }}
                                sx={{ color: "#000" }}
                            >
                                <Close />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Stepper
                    activeStep={activeStep}
                    orientation="vertical"
                    sx={{ mx: 4, my: 2 }}
                >
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel
                                optional={
                                    index === steps.length - 1 ? (
                                        <Typography variant="caption">
                                            Last step
                                        </Typography>
                                    ) : (
                                        index === 0 && (
                                            <Typography variant="caption">
                                                Initial step
                                            </Typography>
                                        )
                                    )
                                }
                            >
                                <Typography fontWeight="bold">
                                    {step.label}
                                </Typography>
                            </StepLabel>
                            <StepContent>
                                <Typography>{step.content}</Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    }}
                                >
                                    {index !== steps.length - 1 && (
                                        <Button
                                            size="small"
                                            variant="contained"
                                            disabled={
                                                searchLoading ||
                                                pageStatus !==
                                                    "RECEIPT_FOUND" ||
                                                filteredData?.data === null ||
                                                filteredData?.data?.length === 0
                                            }
                                            onClick={() => handleNext()}
                                            sx={{
                                                bgcolor: "primary.main",
                                                ":hover": {
                                                    bgcolor: "primary.main",
                                                },
                                                ":disabled": {
                                                    bgcolor: "primary.main",
                                                },
                                                textTransform: "none",
                                                fontWeight: "bold",
                                                width: 120,
                                                p: 1,
                                                mr: 1,
                                                my: 1,
                                            }}
                                        >
                                            <Typography
                                                sx={{ color: "secondary.main" }}
                                            >
                                                Next
                                            </Typography>
                                        </Button>
                                    )}
                                    <Button
                                        size="small"
                                        variant="contained"
                                        onClick={handleBack}
                                        sx={{
                                            ":hover": { bgcolor: "#e4e4e4" },
                                            backgroundColor: "#e4e4e4",
                                            textTransform: "none",
                                            fontWeight: "bold",
                                            width: 120,
                                            p: 1,
                                            mt:
                                                index === steps.length - 1
                                                    ? 3
                                                    : 0,
                                        }}
                                    >
                                        <Typography>Back</Typography>
                                    </Button>
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </DialogContent>
            <DialogActions
                sx={{
                    pl: 4,
                    pt: 2,
                    bgcolor: "#fafafa",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    flex={5}
                >
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            mt: "-0.5em",
                            mr: "-2em",
                        }}
                    >
                        Powered By
                    </Typography>
                    <Avatar
                        alt="inverse"
                        variant="square"
                        src={inverseLogo}
                        sx={{ width: 240 }}
                    />
                </Box>
                <Box flex={1}>
                    <Avatar
                        alt="ride_plus"
                        variant="square"
                        src={ridePlusLogo}
                        sx={{ width: 45, mt: -2 }}
                    />
                </Box>
            </DialogActions>
        </Dialog>
    )
}
export default SearchReceiptsByTIN
