import React, { useEffect, useRef } from "react"
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useSnackbar } from "notistack"
import { useLocation } from "react-router-dom"
import { useMutation, useQuery } from "react-query"
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate.js"

// Corporate TopUp Component
function TopUp(props) {
    const id = useRef()
    const { state } = useLocation()
    const {
        accountType,
        companyId,
        invoice,
        open,
        topUpData,
        setOpen,
        refetch,
    } = props

    // HOOK FORM
    const {
        control,
        handleSubmit,
        formState: { errors },
        register,
    } = useForm({ mode: "onChange" })

    const { enqueueSnackbar } = useSnackbar()

    const axiosPrivate = useAxiosPrivate()

    const { data: banks } = useQuery(
        ["topUp_banks"],
        () => axiosPrivate.get(`/system/configs`),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
        }
    )
    const {
        mutate,
        data: topUp,
        error,
        isLoading,
    } = useMutation((fromData) =>
        axiosPrivate.post(`/system/corporate/${id.current}/top_up`, fromData)
    )

    const onSubmit = (inputData) => {
        const toBeSent = { ...inputData, invoice_number: invoice?.id }
        if (
            accountType !== undefined
                ? accountType === "POSTPAID"
                : state?.corporateData?.account_type === "POSTPAID"
        ) {
            mutate(toBeSent)
        }
        if (
            accountType !== undefined
                ? accountType === "PREPAID"
                : state?.corporateData?.account_type === "PREPAID"
        ) {
            mutate(inputData)
        }
    }
    useEffect(() => {
        if (companyId) {
            id.current = companyId
        } else id.current = state?.corporateData?.id
    }, [companyId, state?.corporateData?.id])
    useEffect(() => {
        if (topUp) {
            enqueueSnackbar(`Succesfully submitted.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000,
            })
            setOpen(false)
            refetch()
        }
    }, [
        enqueueSnackbar,
        setOpen,
        refetch,
        topUp,
        companyId,
        topUpData,
    ])

    useEffect(() => {
        if (error) {
            if (error?.response?.data?.error?.field_error?.length > 0) {
                error?.response?.data?.error?.field_error?.map((msg) =>
                    enqueueSnackbar(msg.description || "Network Error!", {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    })
                )
            } else {
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                )
            }
        }
    }, [enqueueSnackbar, error])

    return (
        <Dialog maxWidth="sm" open={open} onClose={() => setOpen(false)}>
            <DialogTitle
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        {" "}
                        TopUp
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        mx: 1,
                    }}
                >
                    <Controller
                        name="bank_name"
                        control={control}
                        rules={{
                            required: "Please select a bank.",
                        }}
                        render={({ field }) => (
                            <TextField
                                variant="outlined"
                                label="Bank"
                                margin="normal"
                                fullWidth
                                select
                                error={!!errors?.bank_name}
                                helperText={
                                    errors?.bank_name
                                        ? errors.bank_name?.message
                                        : null
                                }
                                size="small"
                                {...field}
                            >
                                {banks?.data?.data?.corporate_topup_supported_banks?.banks?.map(
                                    (item) => (
                                        <MenuItem
                                            value={item?.name}
                                            key={item?.name}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Avatar
                                                    sx={{
                                                        width: 30,
                                                        height: 30,
                                                        mr: 1,
                                                    }}
                                                    src={item?.logo}
                                                />
                                                {item.name}
                                            </Box>
                                        </MenuItem>
                                    )
                                )}
                            </TextField>
                        )}
                    />
                    <Controller
                        name="account_number"
                        control={control}
                        rules={{
                            required: "Account number is required",
                        }}
                        render={({ field }) => (
                            <TextField
                                variant="outlined"
                                label="Account Number"
                                fullWidth
                                margin="normal"
                                autoComplete="account_number"
                                error={!!errors?.account_number}
                                helperText={
                                    errors?.account_number
                                        ? errors.account_number.message
                                        : null
                                }
                                size="small"
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="reference_number"
                        control={control}
                        rules={{
                            required: "Reference number is required",
                        }}
                        render={({ field }) => (
                            <TextField
                                variant="outlined"
                                label="Reference Number"
                                fullWidth
                                margin="normal"
                                autoComplete="reference_number"
                                error={!!errors?.reference_number}
                                helperText={
                                    errors?.reference_number
                                        ? errors.reference_number.message
                                        : null
                                }
                                size="small"
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="amount"
                        control={control}
                        rules={{
                            required: "Amount is required.",
                            // min: 10000,
                        }}
                        render={({ field }) => (
                            <TextField
                                variant="outlined"
                                label="Amount (ETB)"
                                margin="normal"
                                fullWidth
                                type="number"
                                error={!!errors?.amount}
                                helperText={
                                    errors?.amount
                                        ? errors.amount?.message
                                        : null
                                }
                                size="small"
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="remark"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                variant="outlined"
                                label="Note"
                                fullWidth
                                multiline
                                rows={4}
                                margin="normal"
                                autoComplete="remark"
                                error={!!errors?.remark}
                                helperText={
                                    errors?.remark
                                        ? errors.remark.message
                                        : null
                                }
                                size="small"
                                {...field}
                            />
                        )}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                sx={{
                                    "&.Mui-checked": {
                                        color: "#fbcf3b",
                                    },
                                }}
                            />
                        }
                        label="Includes withholding tax"
                        {...register("with_holding")}
                    />
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    mt: 1,
                    pr: 3,
                    pt: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "#e4e4e4" },
                        backgroundColor: "#e4e4e4",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    onClick={() => setOpen(false)}
                >
                    <Typography>Cancel</Typography>
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        bgcolor: "#fdbc3d",
                        ":hover": { bgcolor: "#fdbc3d" },
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={isLoading}
                >
                    <Typography sx={{ color: "secondary.main" }}>
                        Submit
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "black",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                    </Typography>
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default TopUp
