import React from "react"
import { DatePicker } from "@mui/x-date-pickers"
import { TextField, Typography } from "@mui/material"

function CustomDatePicker(props) {
    const { date, value, onChange, text, views } = props
    return (
        <DatePicker
            fullWidth
            minDate={date ? new Date(date) || new Date() : undefined}
            label={
                <Typography
                    sx={{
                        fontWeight: 900,
                        ml: 2,
                    }}
                >
                    {text}
                </Typography>
            }
            value={value}
            views={views}
            onChange={onChange}
            renderInput={(params) => <TextField {...params} />}
        />
    )
}
export default CustomDatePicker
