import {
    Avatar,
    Box,
    Divider,
    Grid,
    TextField,
    Typography,
} from "@mui/material"
import React from "react"
import { useLocation } from "react-router-dom"
import Activities from "../../Component/activities.jsx"
import HasPermission from "../../Utils/access.js"
import { RP_ASSETS_URL, BASE_URL, VERSION } from "../../Utils/config.js"
import { roundToTwoDecimalPlaces } from "../../Utils/dataFormat.js"

// Company Details View Component
function CompanyDetails() {
    const { state } = useLocation()

    return (
        <Grid
            container
            sx={{
                bgcolor: "#fff",
            }}
        >
            <Grid
                item
                sm={HasPermission("list corporate activities") ? 7 : 12}
                xs={12}
                sx={{ mx: 2, mt: 2 }}
            >
                <Typography
                    sx={{
                        fontWeight: "bold",
                        fontSize: 20,
                        ml: { xs: 0, sm: 2 },
                    }}
                >
                    Profile
                </Typography>
                <Grid item>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            my: 2,
                        }}
                    >
                        <Avatar
                            variant="square"
                            sx={{
                                width: { sm: 100, xs: 60 },
                                height: { sm: 90, xs: 50 },
                            }}
                            src={`${BASE_URL}/${VERSION}/${RP_ASSETS_URL}/company_logo/${state.companyData?.logo}`}
                        />
                    </Box>
                </Grid>
                <Grid container>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Corporate Name
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={state.companyData?.name}
                                disabled
                                multiline
                                fullWidth
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Phone
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={state.companyData?.phone}
                                disabled
                                multiline
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Email
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={state.companyData?.email}
                                disabled
                                multiline
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Address
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={state.companyData?.address}
                                disabled
                                multiline
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Sub-City
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={state.companyData?.sub_city}
                                disabled
                                multiline
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                House Number
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={state.companyData?.house_number}
                                disabled
                                multiline
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                License Number
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={state.companyData?.license_number}
                                disabled
                                multiline
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                License Document
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={`${BASE_URL}/${VERSION}/${RP_ASSETS_URL}/company_license/${state.companyData?.license_file}`}
                                disabled
                                multiline
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Balance (ETB)
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={`${roundToTwoDecimalPlaces(
                                    Number(state?.companyData?.balance)
                                )}`}
                                disabled
                                multiline
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Account Type
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={state.companyData?.account_type}
                                disabled
                                multiline
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            {HasPermission("list corporate activities") && (
                <>
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ mt: { sm: 3 }, mb: { sm: 2 } }}
                    />
                    <Grid
                        item
                        sm={4}
                        xs={12}
                        sx={{ mt: 2, ml: { xs: 0, md: 3, sm: 1 } }}
                    >
                        <Activities
                            key={`company_activities_${state.companyData?.id}`}
                            url={`companies/${state.companyData?.id}/activities`}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    )
}
export default CompanyDetails
