export const timeFormatter = (seconds = 0) => {
  const d = Number(seconds);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);
  const hDisplay =
    h > 0 ? `${h.toString().length > 1 ? `${h}` : `${0}${h}`}` : "00";
  const mDisplay =
    m > 0 ? `${m.toString().length > 1 ? `${m}` : `${0}${m}`}` : "00";
  const sDisplay =
    s > 0 ? `${s.toString().length > 1 ? `${s}` : `${0}${s}`}` : "00";
  return `${hDisplay}:${mDisplay}:${sDisplay}`;
};

// display only 2 decimal places without rounding
export const roundToTwoDecimalPlaces = (number = 0) => {
  const numString = number.toString();
  const decimalIndex = numString.indexOf(".");
  if (decimalIndex === -1) {
    return number?.toLocaleString();
  }
  const num = numString.slice(0, decimalIndex + 3);
  return Number(num)?.toLocaleString();
};
// display only 3 decimal places without rounding
export const roundToThreeDecimalPlaces = (number = 0) => {
  const numString = number.toString();
  const decimalIndex = numString.indexOf(".");
  if (decimalIndex === -1) {
    return number?.toLocaleString();
  }
  const num = numString.slice(0, decimalIndex + 4);
  return Number(num)?.toLocaleString();
};

export function formatToTwoDecimalPlaces(value) {
  const roundedValue = Number(value).toFixed(2);
  const parts = roundedValue.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.') === "NaN" ? "--" : parts.join('.');
}

export function formatToThreeDecimalPlaces(value) {
  const roundedValue = Number(value).toFixed(3);
  const parts = roundedValue.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.') === "NaN" ? "--" : parts.join('.');
}
// Minute to 'hh:mm:ss' formatter
export const formatTime = (minutes) => {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = (minutes % 60) - (minutes % 1)
  const seconds = Math.floor((minutes % 1) * 60)
  const formattedTime = `${hours
    .toString()
    .padStart(2, "0")}:${remainingMinutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
  return formattedTime.includes("NaN") ? "--:--:--" : formattedTime
}
// Convert m/s to km/hr
export function convertMStoKMH(metersPerSecond) {
  const kilometersPerHour = (metersPerSecond * 3.6).toFixed(2);
  return kilometersPerHour;
}

// Get a day before a week ago
export function getPreviousDate() {
  const currentDate = new Date();
  const previousDate = new Date(currentDate);
  previousDate.setDate(currentDate.getDate() - 7);

  const formattedDate = previousDate.toISOString();
  const dateTime = `${formattedDate.split("T")[0]}T00:00:00.000+03:00`;

  return dateTime;
}

export function formatString(inputString) {
  // Replace underscores with whitespace
  const stringWithSpaces = inputString.replace(/_/g, ' ');

  // Capitalize the first letter of each word
  const formattedString = stringWithSpaces.replace(/\b\w/g, match => match.toUpperCase());

  return formattedString;
}

