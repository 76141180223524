import React, { useEffect, useState } from "react"
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material"
import { Box } from "@mui/system"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { useSnackbar } from "notistack"
import { useMutation } from "react-query"
import { DateTime } from "luxon"
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate.js"
import CustomDatePicker from "../../../Component/customDatePicker.jsx"

// Invoice generating dialog component
function GenerateInvoice({ open, handleModalClose, refetch, companyId }) {
    // USESTATE & USEREF
    const [fromDate, setFrom] = useState(null)
    const [toDate, setTo] = useState(null)

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // MUTATION
    const { mutate, isLoading, data, error } = useMutation((formData) =>
        axiosPrivate.post(`/system/invoices/generate`, formData)
    )

    // HELPERS & EVENT HANDLERS
    const handleConfirm = () => {
        mutate({
            company_id: companyId,
            from: DateTime.fromISO(fromDate?.toISOString()).toISODate(),
            to: DateTime.fromISO(toDate?.toISOString()).toISODate(),
        })
    }

    // USEEFFECTS
    useEffect(() => {
        if (data) {
            enqueueSnackbar(`Succesfully updated a new Company.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000,
            })
            handleModalClose()
            refetch()
        }
    }, [enqueueSnackbar, handleModalClose, refetch, data])

    useEffect(() => {
        if (error) {
            if (error?.response?.data?.error?.field_error?.length > 0) {
                error?.response?.data?.error?.field_error?.map((msg) =>
                    enqueueSnackbar(msg.description || "Network Error!", {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    })
                )
            } else {
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                )
            }
        }
    }, [enqueueSnackbar, error])

    // RENDER
    return (
        <Dialog fullWidth open={open} onClose={handleModalClose}>
            <DialogTitle
                sx={{
                    backgroundColor: "#FAFAFA",
                    py: 3,
                    boxShadow: "0px 1px 7px #0000001A",
                    borderRadius: "6px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                }}
            >
                <Typography sx={{ fontWeight: "bold" }} variant="h5">
                    Generate Invoice
                </Typography>
            </DialogTitle>

            <DialogContent
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    mt: 1,
                    mb: {
                        sm: -1,
                        xs: 1,
                    },
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: {
                            xs: "column",
                            sm: "row",
                        },
                    }}
                >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "100%",
                                my: 3,
                                mr: { xs: 0, sm: 2 },
                            }}
                        >
                            <CustomDatePicker
                                text="Start"
                                value={fromDate}
                                onChange={(newValue) => {
                                    setFrom(newValue?._d)
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "100%",
                                mt: { sm: 3, xs: 0 },
                                mb: { sm: 3, xs: 0 },
                            }}
                        >
                            <CustomDatePicker
                                text="End"
                                value={toDate}
                                date={fromDate}
                                onChange={(newValue) => {
                                    setTo(newValue?._d)
                                }}
                            />
                        </Box>
                    </LocalizationProvider>
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    background: "#FAFAFA",
                    py: 3,
                    px: 4,
                    display: "flex",
                    justifyContent: "flex-end",
                    boxShadow: "0px 1px 7px #0000001A",
                    borderRadius: "6px",
                }}
            >
                <Button
                    color="inherit"
                    onClick={handleModalClose}
                    sx={{
                        width: 120,
                        fontWeight: "bold",
                        background: "#DFDFDF 0% 0% no-repeat padding-box",
                        borderRadius: "8px",
                        textTransform: "capitalize",
                    }}
                >
                    Cancel
                </Button>
                <Box sx={{ px: 1 }} />
                <Button
                    variant="contained"
                    sx={{
                        width: 120,
                        fontWeight: "bold",
                        color: "#fff",
                        boxShadow: " 0px 2px 5px #00000033",
                        borderRadius: "8px",
                        textTransform: "capitalize",
                    }}
                    onClick={() => handleConfirm()}
                    disabled={isLoading}
                >
                    Confirm
                    {isLoading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: "black",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                            }}
                        />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default GenerateInvoice
